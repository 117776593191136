
.content {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/background.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.alerts {
  position: fixed;
  top: 30px;
  width: auto;
  align-self: center;
  margin: 0 auto;
  z-index: 9999;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logink {
  width: 960px;
  height: 460px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 4px #ccc;
  border-radius: 10px;
  position: relative;
  top: 16%;

  .logink_left_img {
    margin: 0 75px 0 65px;
    display: inline-block;
    width: 392px;
    height: 242px;

    .left_img {
      width: 392px;
      height: 242px;
    }
  }

  .logink_right {
    display: inline-block;
  }
}

.logink_title {
  font-size: 30px;
  color: #3f81c1;
  font-style: normal;
  font-weight: bold;
  width: 336px;
  margin: 0 auto;
}

.login_con {
  position: relative;
}

.longin_search {
  width: 336px;
  max-width: 336px;
  margin-top: 30px;
  height: 48px;
  background: #eef6ff;
  border-radius: 25px;
  font-size: 14px;
  text-indent: 70px;
  outline: none;
}

.longin_search::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #999999;
  /* placeholder字体大小  */
  font-size: 14px;
  /* placeholder位置  */
  text-align: left;
}

.longin_search_img {
  position: absolute;
  top: 46px;
  left: 34px;
}

.login_btn {
  width: 336px;
  /*  width: 110px; */
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin: 0px 5px;
  background-color: #3f81c1;
  border-radius: 50px;
  cursor: pointer;
  float: left;
}

.wangji {
  width: 306px;
  margin: 0 auto;
  text-align: right;
  color: #5473e8;
  font-size: 16px;
}
